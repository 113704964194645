@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.collect-info-container {
  position: relative;

  .container-checkbox {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .container-line-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    input {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    label {
      width: 100%;
    }
  }

  form {
    row-gap: 15px !important;
  }

  .mandatory-fields {
    color: white;
    font-size: 12px;
  }
}

.description-quality {
  text-align: left;
  font-style: normal;
  font-size: 18px;
  color: $color-secondary;
  font-family: Arial;
  font-weight: medium;
}
.question-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

// on mobile
@include mq($until: tablet) {
  .collect-info-container {
    .container-line-input {
      flex-direction: column;
      gap: 20px;
    }
  }
}
