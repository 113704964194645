// @import './../../../../shop/assets/styles/variables.scss';
@import './../../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.confetti {
  position: fixed;
  top: 0%;
  left: 50%;
}

.win-content {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  align-items: center;
  justify-content: center;
  label {
    margin-top: -20px;
  }
}

@include mq($until: mobile) {
  .win-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    justify-content: center;
    label {
      margin-top: -10px;
    }
  }
}
