@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.layout-page {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-bottom: 0;
}

.display-screen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.mobile {
  .display-screen {
    height: 100vh;
  }

  .game-screen {
    width: 80%;
    height: auto;
    border-radius: 19px;

    .txt-date {
      font-family: $default-font, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0.03em;
      text-align: center;
    }

    .description {
      font-family: $default-font, sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.03em;
      text-align: center;
    }

    .check-no-landing {
      font-family: Arial;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.03em;
      text-align: center;
      color: white;
      a {
        color: white;
      }
    }
  }

  .game {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 10;
    background-size: 719.6px 530px;
  }
}
.desktop {
  .display-screen {
    height: 100vh;
    gap: 60px;
  }
  .check-no-landing {
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #ffffff;
    margin: 0;
    a {
      color: white;
    }
  }

  .background {
    z-index: 20;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    margin: auto;
  }
  .game {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
  }
}

.game-screen {
  display: flex;
  font-family: $default-font;
  padding: 40px 20px;
  border-radius: 20px;
  width: 584px;
  height: 510px;
  .txt-date {
    color: $color-secondary;
  }

  .game-screen-content {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    row-gap: 25px;
    form {
      max-width: 100%;
      row-gap: 30px;
    }
  }
}

.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include mq($from: mobile, $and: mq($until: m-height, $media-feature: height)) {
  .game-screen {
    margin-top: 30px;
  }
}
